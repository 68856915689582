import React from "react"
import styled from "styled-components";
import Container from "../../styles/Container";
import {StaticImage} from "gatsby-plugin-image";
import ContainerSmall from "../../styles/ContainerSmall";


const LogosInterior = styled(ContainerSmall)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  &:nth-child(1) {
  padding-bottom: 30px;
  }
  
    &:nth-child(2) {
  padding-top: 30px;
  }

  > div {
    margin: 10px;
  }

   @media(max-width: ${props => props.theme.screenSmMax}) {
     flex-wrap: wrap;
     justify-content: space-around;
     > div {
      margin: 20px;
     }
   }
`;


const Logos = () => {

  return (
    <Container>
      <LogosInterior>
        <StaticImage
          src={'../../../content/assets/logos/logo-villanova.png'}
          alt="Villanova University Logo"
          placeholder="blurred"
          layout="fixed"
          width={130}
        />
        <StaticImage
          src={'../../../content/assets/logos/suny-corning.png'}
          alt="SUNY Corning Logo"
          placeholder="blurred"
          layout="fixed"
          width={130}
        />
        <StaticImage
          src={'../../../content/assets/customers/customer-howard.png'}
          alt="Howard University Logo"
          placeholder="blurred"
          layout="fixed"
          width={130}
        />
        <StaticImage
          src={'../../../content/assets/logos/virginia-beach.png'}
          alt="Virginia Beach Public Schools Logo"
          placeholder="blurred"
          layout="fixed"
          width={130}
        />
        <StaticImage
          src={'../../../content/assets/logos/logo-fullsail.png'}
          alt="Full Sail University Logo"
          placeholder="blurred"
          layout="fixed"
          width={130}
        />

      </LogosInterior>
      <LogosInterior>
        <StaticImage
          src={'../../../content/assets/logos/logo-cia.png'}
          alt="Culinary Institute of America Logo"
          placeholder="blurred"
          layout="fixed"
          width={150}
        />
        <StaticImage
          src={'../../../content/assets/logos/logo-concorde.png'}
          alt="Concorde Career Colleges Logo"
          placeholder="blurred"
          layout="fixed"
          width={150}
        />
        <StaticImage
          src={'../../../content/assets/logos/logo-ccsnh.png'}
          alt="Community College System of New Hampshire Logo"
          placeholder="blurred"
          layout="fixed"
          width={150}
        />
        <StaticImage
          src={'../../../content/assets/logos/logo-usf.png'}
          alt="University of South Florida Logo"
          placeholder="blurred"
          layout="fixed"
          width={150}
        />

        <StaticImage
          src={'../../../content/assets/logos/logo-bristol.png'}
          alt="Bristol Community College Logo"
          placeholder="blurred"
          layout="fixed"
          width={150}
        />
      </LogosInterior>
    </Container>
  );
}

export default Logos;
