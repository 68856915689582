import React from "react"
import Container from "../../styles/Container";
import ContainerSmall from "../../styles/ContainerSmall";

import styled from "styled-components";

import {graphql, StaticQuery} from "gatsby";
import Post from "./Post";


const PostsWrapper = styled.div`
  background: #fff;
  margin-top: -50px; // underlay features section
  z-index: 5;
  position: relative;
`;

const PostsInterior = styled(ContainerSmall)`
  padding-top: 100px;
  padding-bottom: 100px;
  h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 1.75rem;
    line-height: 33/28;
    letter-spacing: 0.03em;
    color: ${props => props.theme.black};
    padding-bottom: 20px;
    border-bottom:  2px solid ${props => props.theme.black};
  }
`;

const Posts = (props) => {

  var text = 'Latest Blog Posts'
  if(props.subjectTitle) {
    text = 'Latest ' + props.subjectTitle + ' Blog Posts';
  }

  return (
    <PostsWrapper>
      <Container>
        <PostsInterior>


          <h2>{text}</h2>

          {/*Sorting by date and limiting this query to 3 would be ideal, but looks like it doesn't work with the date*/}
          {/*format in our frontmatter.*/}

          <StaticQuery
            query={graphql`
           query {
                allMarkdownRemark(filter: {frontmatter: {type: {eq: "blog"}}}, sort: {fields: id, order: DESC}) {
                  edges {
                    node {
                      fields {
                        slug
                      }
                      frontmatter {
                        date
                        title
                        path
                        tags
                      }
                    }
                  }
                }
          }
      `}
            render={(data) => {

              let posts = data.allMarkdownRemark.edges.sort((a, b) => {
                return Date.parse(b.node.frontmatter.date) - Date.parse(a.node.frontmatter.date);
              });

              // If we have a filter, filter by tags
              if (props.tag) {
                posts = posts.filter((item) => {
                  return item.node.frontmatter.tags !== null && item.node.frontmatter.tags.indexOf(props.tag) !== -1;
                });
              }


              posts = posts.slice(0, 3);

              return posts.map(({node}) => {

                return (
                  <Post
                    key={node.fields.slug}
                    path={node.frontmatter.path}
                    title={node.frontmatter.title}
                    date={node.frontmatter.date}
                  />

                )
              });
            }}
          />

        </PostsInterior>
      </Container>
    </PostsWrapper>
  )
}

export default Posts;
