import React from "react"

import styled from "styled-components";
import CaseStudyItem from "./CaseStudyItem";
import ContainerSmall from "../../styles/ContainerSmall";
import {graphql, useStaticQuery} from "gatsby";


const CaseStudiesDiv = styled(ContainerSmall)`
  padding-top: 60px;
  padding-bottom: 100px;
  h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 2.875rem;
    line-height: 54/46;
    text-align: center;
    letter-spacing: 0.03em;
    color: #000;
    margin-top: 0;
    margin-bottom: 50px;
  }
  
    > p {
    font-size: ${props => props.theme.fontMdSm};
    text-align: center;
    padding-bottom: 40px;
  }
`;

const CaseStudiesInterior = styled.div`
  display: grid;
  gap: 75px;
  grid-template-columns: 1fr 1fr 1fr;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    gap: 30px;
    grid-template-columns: 1fr;
  }
`;

const CaseStudies = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {type: {eq: "casestudy"}}}) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              path
              logo
              school
              published
              tag
              lead
              featured
            }
          }
        }
      }
    }
  `)

  // First filter to just published. Could probably take care of this in query
  let posts = data.allMarkdownRemark.edges.filter((item) => {
    return item.node.frontmatter.published;
  });

  // Sort by order
  posts = posts.sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order);



  // If it's tagged, sort those out
  if (props.tag) {
    posts = posts.filter((item) => {
      return item.node.frontmatter.tag === this.props.tag;
    });
  } else {
    // Otherwise just use the featured posts
    posts = posts.filter((item) => {
      return item.node.frontmatter.featured;
    });
  }

  posts = posts.slice(0, 3);


  let items = posts.map(({node}) => {
    return (
      <CaseStudyItem
        title={node.frontmatter.school}
        lead={node.frontmatter.lead}
        path={node.frontmatter.path}
        logo={node.frontmatter.logo}
      />
    )
  });


  return (
    <CaseStudiesDiv>
      <h2>Recent case studies</h2>
      <p>How we’ve helped institutions of every size manage their catalogs, curriculums and syllabi.</p>
      <CaseStudiesInterior>
        {items}
      </CaseStudiesInterior>
    </CaseStudiesDiv>
  )
}

export default CaseStudies;
