import React from "react"
import styled from "styled-components";
import 'react-tabs/style/react-tabs.css';
import Accessibility from "../../icons/accessibility.svg";
import Api from "../../icons/api.svg";
import Pdf from "../../icons/pdf.svg";
import Build from "../../icons/build.svg";
import Design from "../../icons/design.svg";
import Workflow from "../../icons/workflow.svg";
import Speed from "../../icons/speed.svg";
import Template from "../../icons/template.svg";
import {graphql, useStaticQuery} from "gatsby";
import Link from "gatsby-link";
import Arrow from "../../icons/arrow.svg";


const FeatureItemWrapper = styled.div`
  border-top: 2px solid #000000;

  > svg {
    margin-top: 30px;
    height: 40px;
    fill: ${props => props.theme.red};

    path {
      fill: ${props => props.theme.red};
    }
  }

}

`;


const PostTitle = styled(Link)`
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  padding-top: 19px;
  padding-bottom: 10px;
  &:hover,
  &:focus {
    h3 {
      color: ${props => props.theme.red};
    }

    path {
      fill: ${props => props.theme.red};
    }
  }

  h3 {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.75rem;
    line-height: 150%;
    color: ${props => props.theme.black};
    max-width: 90%;
    margin: 0;

    @media (max-width: ${props => props.theme.screenSmMax}) {
      font-size: 1.25rem;
    }

  }
  svg {
    width: 22px;
    height: 20px;
  }
}





`;


const FeatureItem = (props) => {


  // Get all our blog posts
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {type: {eq: "feature"}}}) {
        edges {
          node {
            frontmatter {
              icon
              description
              path
              tag
              teaser
              vimeoid
              title
            }
          }
        }
      }
    }
  `);


  // Filter to just our current feature
  let features;
  if (props.tag) {
    features = data.allMarkdownRemark.edges.filter((item) => {
      return item.node.frontmatter.tag === props.tag;
    });
  }

  let feature = features[0].node.frontmatter;

  // Really dumb way of getting the right icon
  let icon;
  switch (props.tag) {
    case 'accessibility':
      icon = <Accessibility/>
      break;
    case 'api':
      icon = <Api/>
      break;
    case 'pdf':
      icon = <Pdf/>
      break;
    case 'buildout':
      icon = <Build/>
      break;
    case 'customizable':
      icon = <Design/>
      break;
    case 'workflows':
      icon = <Workflow/>
      break;
    case 'fundamentals':
      icon = <Speed/>
      break;
    case 'template':
      icon = <Template/>
      break;
    default:
      console.log('Icon not found');
  }

  return (
    <FeatureItemWrapper>
      {icon}
      <PostTitle to={feature.path}>
        <h3>{feature.title} </h3>
        <Arrow/>
      </PostTitle>
      <p>{feature.description}</p>
    </FeatureItemWrapper>
  )
}

export default FeatureItem;
