import React from 'react';
import styled from "styled-components";


const StyledTab = styled.button`
    border: none;
    border-radius: 0;
    display: inline-flex;
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 130%;
    opacity: 0.7;
    border-left: 2px solid #0D3745;
    padding-left: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
    /* identical to box height, or 29px */
    letter-spacing: 0.03em;
    color: #0D3745;

    &:hover {
      cursor: pointer;
    }
    &:focus {
      border-right-color: transparent;
      border-top-color: transparent;
      border-bottom-color: transparent;
      box-shadow: none;
    }

    &.open{
    padding-left: 27px; // account for difference in borders
    opacity: 1;
    border-color: transparent;
    border-left: 5px solid #0D3745;
    box-shadow: none;
    }

`;

const OpenStyledTab = styled(StyledTab)`

    padding-left: 27px; // account for difference in borders
    opacity: 1;
    border-color: transparent;
    border-left: 5px solid #0D3745;
    box-shadow: none;
   
`;




const Tab = (props) => {
  let render = <StyledTab onClick={() => props.onButtonClick(props.id)}>{props.text}</StyledTab>;
  if(props.isOpen) {
    render = <OpenStyledTab onClick={() => props.onButtonClick(props.id)}>{props.text}</OpenStyledTab>
  }

  return (
    render
  )
}

export default Tab;
