import React from "react"
import styled from "styled-components";
import Container from "../../styles/Container";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../Button";
import ArrowLink from "../ArrowLink";


const HeroWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  @media(max-width: ${props => props.theme.screenMdMax}) {
    flex-direction: column;
  }

  // Hiding images on mobile for pagespeed purposes.
  .gatsby-image-wrapper {
    @media(max-width: ${props => props.theme.screenMdMax}) {
      display: none;
    }
  }

  > div:first-child {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media(min-width: ${props => props.theme.screenMd}) {
      padding-right: 112px;
    }
  }

  > div:nth-child(2) {
    max-width: 650px;
    flex: 1 1 auto;
    width: 100%;
    @media(max-width: ${props => props.theme.screenMdMax}) {
      margin-top: 30px;
    }
  }

`;

const StyledH1 = styled.h1`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 4.2rem;
  line-height: 84/76;
  letter-spacing: 0.03em;
  color: ${props => props.theme.black};
  @media(max-width: ${props => props.theme.screenSmMax}) {
    font-size: 2.5rem;
  }
`;

const SubHeading = styled.p`
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.375rem;
  line-height: 150%;
  color: ${props => props.theme.black};
`;

const Hero = (props) => {

  let button;
  if (props.buttonStyle === 'button') {
    button = <Button
      path={props.linkPath}
      text={props.linkText}
    />;
  } else {
    button = <ArrowLink
      path={props.linkPath}
      text={props.linkText}
    />;
  }

  return (
    <div>
      <Container>
        <HeroWrapper>
          <div>
            <div>
              <StyledH1>{props.title}</StyledH1>
              <SubHeading>
                {props.text}
              </SubHeading>
              {button}
            </div>
          </div>

          <div>
            <GatsbyImage image={props.imgSrc} alt={props.imgAlt} />
          </div>
        </HeroWrapper>
      </Container>
    </div>
  );
}

export default Hero;
