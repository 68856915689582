import React from "react"
import styled from "styled-components";
import Arrow from "../../icons/arrow.svg";
import Link from "gatsby-link";


const PostWrapper = styled.article`
  margin-top: 30px;
`;

const PostTitle = styled(Link)`

  display: flex;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  h3 {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.75rem;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: ${props => props.theme.black};
    margin-bottom: 5px;
    margin-top: 0;
    max-width: 90%;
    @media(max-width: ${props => props.theme.screenSmMax}) {
      font-size: 1.25rem;
    }
  }

  svg {
    width: 22px;
    height: 20px;
  }

  &:hover,
  &:focus {
    h3 {
      color: ${props => props.theme.red};
    }
    path {
      fill: ${props => props.theme.red};
    }
  }

`

const StyledDate = styled.span`
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 22/16;
  letter-spacing: 0.03em;
  color: ${props => props.theme.black};
`

const Post = (props) => {

  return (
      <PostWrapper>
        <PostTitle to={props.path}>
          <h3>{props.title}</h3>
          <Arrow />
        </PostTitle>
        <StyledDate>{props.date}</StyledDate>
      </PostWrapper>
  )
}

export default Post;
