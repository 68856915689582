import React, {useState} from 'react';
import styled from "styled-components";
import Container from "../../styles/Container";
import Tab from "./Tab";
import ContainerSmall from "../../styles/ContainerSmall";
import TabPanel from "./TabPanel";


const FeaturesInterior = styled.div`
  background: #FFF;
  box-shadow: ${props => props.theme.boxShadow};
  // Overlay resources section
  position: relative;
  z-index: 10;
  padding-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
  @media(min-width: ${props => props.theme.screenSm}) {
    padding-top: 80px;
    padding-left: 110px;
    padding-bottom: 100px;
    padding-right: 110px;
  }
`;


const StyledH2 = styled.h2`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 2.875rem;
  line-height: 54/46;
  text-align: center;
  letter-spacing: 0.03em;
  color: ${props => props.theme.black};
  margin-bottom: 15px;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    font-size: 1.5rem;
  }
`

const StyledSubheading = styled.p`
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 33/22;
  text-align: center;
  color: ${props => props.theme.black};
  margin-bottom: 72px;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    font-size: 1rem;
  }
`
const StyledTabs = styled.div`
  display: flex;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    flex-direction: column;
  }
`;

const StyledTabWrapper = styled.div`
 width: 100%;
`;

const StyledTabList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
  @media(min-width: ${props => props.theme.screenSm}) {
    margin-bottom: 0;
    padding-right: 59px;
    width: 280px;
    flex: 0 0 280px;
  }
`

function Features() {


  const [open, setOpen] = useState(1);

  const handleClick = (id) => {
    setOpen(id);
  }

  return (


    <Container>

      <FeaturesInterior>
        <ContainerSmall>
          <StyledH2>Buildout Done For You</StyledH2>
          <StyledSubheading>You give us your current document in whatever form it's in, and we deliver a
            fully-functioning
            site ready for you to use — at no additional cost.</StyledSubheading>

          <StyledTabs>
            <StyledTabList>
              <Tab
                onButtonClick={handleClick}
                text={'Improve the Student Experience'}
                id={1}
                isOpen={open === 1}
                />
              <Tab
                onButtonClick={handleClick}
                text={'Streamline Your Processes'}
                id={2}
                isOpen={open === 2}
              />
              <Tab
                onButtonClick={handleClick}
                text={'Use the Best Technology'}
                id={3}
                isOpen={open === 3}
              />
            </StyledTabList>

            <StyledTabWrapper>
              <TabPanel
                tags={['accessibility', 'customizable']}
                isOpen={open === 1}
                />
              <TabPanel
                tags={['buildout', 'pdf', 'workflows', 'template']}
                isOpen={open === 2}
              />
              <TabPanel
                tags={['api', 'fundamentals']}
                isOpen={open === 3}
              />

            </StyledTabWrapper>
          </StyledTabs>
        </ContainerSmall>
      </FeaturesInterior>

    </Container>
  )
}

export default Features;
