import React from 'react';
import styled from "styled-components";
import FeatureItem from "./FeatureItem";


const StyledTabPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    @media(min-width: ${props => props.theme.screenSm}) {
      width: 315px;
      flex: 0 0 315px;
      margin-bottom: 30px;
    }
  }
`;




function TabPanel(props) {
  if(!props.isOpen) {
    return null;
  }

  return (
    <StyledTabPanel>
      {props.tags.map(item => {
        return <FeatureItem
          tag={item}
        />
      })}
    </StyledTabPanel>

  )
}

export default TabPanel;
