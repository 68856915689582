import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/homepage/Hero";
import Logos from "../components/homepage/Logos";
import Features from "../components/homepage/Features";
import Posts from "../components/homepage/Posts";
import {graphql, useStaticQuery} from "gatsby";
import {getImage} from "gatsby-plugin-image";
import CaseStudies from "../components/homepage/CaseStudies";


const Index = () => {

  const data = useStaticQuery(graphql`{
  hero: file(absolutePath: {regex: "/home-hero.jpg/"}) {
    childImageSharp {
      gatsbyImageData(width: 650, quality: 80, layout: CONSTRAINED)
    }
  }
}
`);


  return (
    <Layout>

      <Hero
        title={'Course Catalog & Curriculum Software'}
        text={'The modern catalog and curriculum platform for universities, colleges, and school districts.'}
        linkText={'Case Studies'}
        linkPath={'/case-studies/'}
        imgSrc={getImage(data.hero)}
        imgAlt={'Student using course catalog software on a laptop'}
      />
      <Logos/>
      <Features/>
      <CaseStudies />
      <Posts/>
    </Layout>
  )
}

export default Index;

export const Head = (props) => {
  return (
    <Seo
      title={'Curriculum and Course Catalog Software'}
      canonical={props.location.pathname}
    />
  )
};
