import React from "react"
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import ArrowLink from "../ArrowLink";

const CaseStudyItemDiv = styled.div`
  h3 {
    margin-top: 20px;
    margin-bottom: 5px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.375rem;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: ${props => props.theme.black};
  }
  p {
    margin-top: 0;
    margin-bottom: 30px;
  }
  a {
    width: 100%;
  }
`;

const CaseStudyItem = (props) => {

  // Get all of our images, and then filter, since we can't use props in the query
  const data = useStaticQuery(graphql`{
  allFile(filter: {relativeDirectory: {eq: "logos"}}) {
    nodes {
      childImageSharp {
        gatsbyImageData(height: 80, placeholder: NONE, layout: FIXED)
      }
    name
    }
  }
}
`);


  // Filter to just our current image, passed down in props
  let src = data.allFile.nodes.filter((item) => {
    return props.logo === item.name;
  });

  let imgSrc = src.filter((item) => {
    return item.node !== null && item.childImageSharp.gatsbyImageData !== null;
  });


  return (
    <CaseStudyItemDiv>
      <GatsbyImage image={getImage(imgSrc[0])} alt={props.title + ' logo'} />
      <h3>{props.title}</h3>
      <p>{props.lead}</p>
      <ArrowLink
        path={props.path}
        text={'Read Case Study'}
      />
    </CaseStudyItemDiv>
  );
}

export default CaseStudyItem;
